import React, { Component } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

import './home.css';

class Home extends Component {
  constructor() {
    super();
    this.state = {
      tweets: [
        '1121776031357198336',
        '1121511722395762695',
        '1121403518852771847',
        '1121402248712654848',
        '1121515474196619264',
        '1118108755357343744',
        '1115950202735738880',
        '1121386491492519938',
        '1121763396641263616',
        '1121754995827908608',
        '1120654819444895745',
        '1120414329197813760',
        '1118893241137598466',
        '1121819873506680832',
        '1120318838661369861',
        '1121760851017515010',
        '1118869288893464576',
        '1121825582344548358',
        '1121522146805723137',
        '1120340371815505922',
        '1119295810557087744',
        '1121647647515799552',
        '1121429148365467648',
        '1121348194565332992',
        '1121838873502470146',
        '1121821001489289217',
        '1121749483472195585',
        '1120787953293377536',
        '1121853556141719552',
        '1120783469171433472'
      ],
      currentPage: 1,
      tweetsPerPage: 50
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id)
    });
  }

  render() {
    const { tweets, currentPage, tweetsPerPage } = this.state;

    // Logic for displaying tweets
    const indexOfLastTweet = currentPage * tweetsPerPage;
    const indexOfFirstTweet = indexOfLastTweet - tweetsPerPage;
    const currentTweets = tweets.slice(indexOfFirstTweet, indexOfLastTweet);

    const renderTweets = currentTweets.map((tweet, index) => {
      // this would be
      return <li key={index} page={currentPage}><TwitterTweetEmbed tweetId={tweet} /></li>;
      // return <li key={index} page={currentPage}><TwitterTweetEmbed tweetId={'1117537039015141376'} /></li>;
    });

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(tweets.length / tweetsPerPage); i++) {
      pageNumbers.push(i);
    }

    const renderPageNumbers = pageNumbers.map(number => {
      return (
        <li key={number} id={number} className="pagination-item" onClick={this.handleClick}>
          {number}
        </li>
      );
    });

    return (
      <div className="container">
        <main className="content-wrapper">
          <ul className="list-wrapper">
            {renderTweets}
          </ul>
          <ul className="pagination-wrapper">
            {renderPageNumbers}
          </ul>
        </main>
      </div>
    );
  }
}

export default Home;
