import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import Home from './Home';
import Archive from './Archive';

import './app.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="App">
          <header className="header">
          <Link to="/takeback">
            Take Back
          </Link>
          <Link to="/archive">
            Archive
          </Link>
          </header>
          <Route exact path="/takeback" component={Home} />
          <Route exact path="/archive" component={Archive} />
        </div>
      </Router>
    );
  }
}

export default App;
