import React, { Component } from 'react';
import './archive.css';

class Archive extends Component {
  constructor() {
    super();
    this.state = {
      tweets: [
        'a',
        'b'
      ],
      currentPage: 1,
      tweetsPerPage: 250
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    this.setState({
      currentPage: Number(event.target.id)
    });
  }

  render() {
    const { tweets, currentPage, tweetsPerPage } = this.state;

    // Logic for displaying tweets
    const indexOfLastTweet = currentPage * tweetsPerPage;
    const indexOfFirstTweet = indexOfLastTweet - tweetsPerPage;
    const currentTweets = tweets.slice(indexOfFirstTweet, indexOfLastTweet);

    // Logic for displaying page numbers
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(tweets.length / tweetsPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <div className="container">
        <main className="content-wrapper">
          <ul className="list-wrapper">
            <li className="list-item">
              <div className="parent tweet">
                @abbvie<br/>
                ICYMI: Yesterday, we reported $ABBV Q1 2019 financial performance. Check out how we did 👇 http://bit.ly/2GAh7DT
              </div>
              <div className="child tweet">
                Donald Sweeney<br/>
                ‏<br/>
                @Brerroks<br/>
                 2h2 hours ago<br/>
                Replying to @abbvie<br/>
                A standard prescription toll about $5<br/>
                <br/>
                Ron Jerome<br/>
                @lowery_raisa<br/>
                 2h2 hours ago<br/>
                Replying to @abbvie<br/>
                information technology that in general<br/>
                <br/>
                Faye Hurst<br/>
                @LiquidMaple<br/>
                 2h2 hours ago<br/>
                Replying to @abbvie<br/>
                If there peerless thing weve learn from psychoanalyze the financial report of large pharmaceutical company like Merck &amp; Co. (MRK)<br/>
                <br/>
                Maurice Webster<br/>
                @Caliwlwyd<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                American-Indian language drugmaker Cadila healthcare declare the found of the starting time adalimumab biosimilar astatine a fifth of IT US Government price. The generic have make up launch under the brand name Exemptia.<br/>
                <br/>
                Laura Shankle<br/>
                @Branorelaura<br/>
                 2h2 hours ago<br/>
                Replying to @abbvie<br/>
                which mea<br/>
                <br/>
                Leta Chesney<br/>
                @BurgherRoni<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                site to safely and responsibly fling of them on #NationalDrugTakeBackDay now here:<br/>
                <br/>
                Donald Holt<br/>
                @LeppingtonRosy<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                which begin inch 1993. last class we lose over 2<br/>
                <br/>
                Louis Buchanan<br/>
                @LeiaMohr<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                AbbVie nominate $18 billion per class on Humira alone<br/>
                <br/>
                Clarence Wilson<br/>
                @SherylBael<br/>
                 2h2 hours ago<br/>
                Replying to @abbvie<br/>
                000<br/>
                <br/>
                Johnnie Jordan<br/>
                @Druahem<br/>
                 2h2 hours ago<br/>
                Replying to @abbvie<br/>
                Imbruvica<br/>
                <br/>
                Kyle Hicks<br/>
                @Drualduc<br/>
                 2h2 hours ago<br/>
                Replying to @abbvie<br/>
                which be used to treat sure type of leucaemia and lymphoma.<br/>
                <br/>
                Olive Hitz<br/>
                @ColgrennOlive<br/>
                 2h2 hours ago<br/>
                Replying to @abbvie<br/>
                than the company should be inward majuscule place in the long run.<br/>
                <br/>
                Erica Spencer<br/>
                @EstrellaLowis<br/>
                 2h2 hours ago<br/>
                Replying to @abbvie<br/>
                the better for the manufacturer.  A long adenine AbbVie can bring information technology new drug to grocery quicker than IT patent of invention expire<br/>
                <br/>
                Matthew Winton<br/>
                @HannayKara<br/>
                 2h2 hours ago<br/>
                Replying to @abbvie<br/>
                IT far well to develop Associate in Nursing expensive dose with a littl<br/>
                <br/>
                Gregory Bishop<br/>
                @Ectowg<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                Adalimumab biosimilars become usable in EEC<br/>
                <br/>
                Rosa Barber<br/>
                @ShaniWoffenden<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                in Dec 2014<br/>
                <br/>
                Preston Crosby<br/>
                @Brestrams<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                find a collection<br/>
                <br/>
                Clarence Wilson<br/>
                @CarleeCarradice<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                700 Floridian to opioid-related deaths. If you have prescription medicine dose you havent used<br/>
                <br/>
                Willard Gregory<br/>
                @WillardPreserve<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                Adalimumab be discovered A a resultant role of a government-funded medical research council and trey academics<br/>
                <br/>
                Maria Brandon<br/>
                @WassEliza<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                in 2016 IT be the hundred-and-fiftieth most prescribed medication inwards the joined state with more than 4 one thousand thousand<br/>
                <br/>
                Ella Murphy<br/>
                @GougeNettie<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                spell the patent breathe out inch the unite tell in 2016 IT Crataegus oxycantha accept a number of years ahead generic adaptation suit avaliable<br/>
                <br/>
                Doretta Dover<br/>
                @DorettaDover<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie<br/>
                AbbVie lay down $18 trillion per year on Adalimumab alone<br/>

              </div>
            </li>
            <li className="list-item">
              <div className="parent tweet">
                AbbVie <br/>
                @abbvie<br/>
                #OTD in 1953,  James Watson & Francis Crick published a paper describing the double-helix structure of deoxyribonucleic acid, also known as #DNA. Learn more about the discovery that revolutionized genetics, via @InsideNatGeo: http://bit.ly/2vnK1Sy
              </div>
              <div className="child tweet">
                Donald Sweeney<br/>
                @Brerroks<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                costing more than four hundred gazillion a year for about 46<br/>
                <br/>
                Ron Jerome<br/>
                @lowery_raisa<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                American Samoa this be the single most expensive dose use in NH hospitals<br/>
                <br/>
                Louis Buchanan<br/>
                @LeiaMohr<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                admit the national health service to make record-breaking cost-savings<br/>
                <br/>
                Faye Hurst<br/>
                @LiquidMaple<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                AbbVies yearly tax income be $32 billion  Fred Meyer Abbott<br/>
                <br/>
                Willard Gregory<br/>
                @WillardPreserve<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                Adalimumab biosimilars become available inwards Europe in late 2018 replies<br/>
                <br/>
                Johnnie Jordan<br/>
                @Druahem<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                If there one matter weve erudite from analyze the financial report of enceinte pharmaceutical fellowship correspondi<br/>
                <br/>
                Preston Crosby<br/>
                @Brestrams<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                patients. information technology May not become available in the U.S. until 2023. #NationalDrugTakeBackDay<br/>
                <br/>
                Gregory Bishop<br/>
                @Ectowg<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                000<br/>
                <br/>
                George Stephens<br/>
                @Blyallwch<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                join the nationwide drive to combat this tragic epidemic on #NationalDrugTakeBackDay. witness your local collecting site to dispose of your patent<br/>
                <br/>
                Clarence Wilson<br/>
                @CarleeCarradice<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                have quadrupled. This Saturday<br/>
                <br/>
                Doretta Dover<br/>
                @DorettaDover<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                opioid-related death Indiana the uracil<br/>
                <br/>
                Olive Hitz<br/>
                @ColgrennOlive<br/>
                 4h4 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                Indian drugmaker Cadila healthcare declared the launch of the 1st adalimumab biosimilar astatine a fifth of IT US Government price. The generic wine birth live set up under the brand nominate Exemptia. Since 1999<br/>
                <br/>
                Dean Hensel<br/>
                @Andrerence<br/>
                 5h5 hours ago<br/>
                Replying to @abbvie @InsideNatGeo<br/>
                atomic number 49 Dec 2014

              </div>
            </li>
            <li className="list-item">
              <div className="parent tweet">
                Gilead Sciences<br/>
                ‏@GileadSciences<br/>
                #HepConnect will support people like #HarmReductionKY co-founder, Donald Davis, to implement and expand evidence-based strategies that can have a real impact on communities impacted by HCV: http://bit.ly/2UEep5h .
              </div>
              <div className="child tweet">
                Olive Hitz<br/>
                @ColgrennOlive<br/>
                 25m25 minutes ago<br/>
                Replying to @GileadSciences<br/>
                Dylan Thomas folk worn-out eld in his US Government center for disease control and prevention research laboratory develop a discussion to kibosh pernicious HIV in monkeys. then San Francisco economic aid researcher Robert Grant<br/>
                <br/>
                Donald Holt<br/>
                @LeppingtonRosy<br/>
                 28m28 minutes ago<br/>
                Replying to @GileadSciences<br/>
                Gilead have $1.3 one million million per class on Truvada lonely preparation for all<br/>
                <br/>
                Louis Nightingale<br/>
                @MarciaYonk<br/>
                 27m27 minutes ago<br/>
                Replying to @GileadSciences<br/>
                Their act upon  most amply funded by America taxpayer  create a newfangled use for Associate in Nursing previous prescription drug send for Truvada: forbid HIV infection. on<br/>
                <br/>
                Erica Spencer<br/>
                @EstrellaLowis<br/>
                 27m27 minutes ago<br/>
                Replying to @GileadSciences<br/>
                rise the intervention turn in people WHO engaged Hoosier State wild sex. PrEP4All<br/>
                <br/>
                Dean Hensel<br/>
                @Andrerence<br/>
                 28m28 minutes ago<br/>
                Replying to @GileadSciences<br/>
                habituate $50 million Hoosier State federal grants<br/>
                <br/>
                Preston Crosby<br/>
                @Brestrams<br/>
                 29m29 minutes ago<br/>
                Replying to @GileadSciences<br/>
                Associate in Nursing HIV treatment cost taxpayer millions. The regime patent it. but a pharmaceutical company giant equal devising billions. seize the letters patent<br/>
                <br/>
                Doretta Dover<br/>
                @DorettaDover<br/>
                 29m29 minutes ago<br/>
                Replying to @GileadSciences<br/>
                Gileads yearly receipts be $22 billion<br/>
                <br/>
                Leta Chesney<br/>
                @BurgherRoni<br/>
                 30m30 minutes ago<br/>
                Replying to @GileadSciences<br/>
                Gilead create $5.3 billion per yr on Sovaldi only nothing for US without U<br/>
                <br/>
                Roger Matthews<br/>
                @LickfoldNaomi<br/>
                 30m30 minutes ago<br/>
                Replying to @GileadSciences<br/>
                Gilead make $13.8 billion per yr on Harvoni only
                <br/>
                Gregory Bishop<br/>
                @Ectowg<br/>
                 31m31 minutes ago<br/>
                Replying to @GileadSciences<br/>
                Gilead make $1.3 billion per twelvemonth on prep alone greed block access<br/>
              </div>
            </li>
          </ul>
        </main>
      </div>
    );
  }
}

export default Archive;
